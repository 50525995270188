import React, { Component } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";

import "./Cancellation.scss";

import apiContext from "../../api";

import Modal from "../../components/Modal/Modal";
import HomeTemplate, { redirect } from "../HomeTemplate/HomeTemplate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastOperations } from "../../state/ducks/toast";
import { loadingOperations } from "../../state/ducks/loading";
import WarningImage from "../../assets/illustrations/warning.png";
import PageHeaderImage from "../../components/PageHeaderImage/PageHeaderImage";
import ServiceItem from "../../components/ServiceItem/ServiceItem";
import { IconHome, IconClose } from "../../components/Icons/Icons";
import ClienteService from "../../services/cliente";

class CancellationComplement extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      whenWillLeave: null,
      leavingDate: null,
      protocolExists: false,
    };
  }

  validateLeaveDate = (leavingDate) => {
    const dateObj = new Date(
      `${leavingDate.split("/").reverse().join("-")}T00:00:00.000Z`
    );

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    return +today + 3 * 24 * 60 * 60 * 1000 >= +dateObj;
  };

  handleAvancar(onSubmit, whenWillLeave, leavingDate) {
    if (
      leavingDate &&
      !this.validateLeaveDate(leavingDate)
    ) 
      return onSubmit({ success: false });

    onSubmit({ whenWillLeave, leavingDate, success: true })
  }

  componentDidMount() {
   this.verificaProtocolo()
  }

  verificaProtocolo() {
    const { [`cod-emitente`]: codEmitente } = ClienteService.localEntrega;
    const { ['local-entrega']: localEntrega, ['cod-motivo']: codMotivo } = this.props.address

    this.props.showLoading();

    this.context.ConsigazApi.verificaProtocolos(
      codEmitente,
      localEntrega,
      codMotivo,
      "Cancelamento"
    ).then(
      ({ Retorno }) => {
        this.props.dismissLoading();        
        if (Retorno?.length > 0) {
          this.setState({ ...this.state, protocolExists: true })
          this.modalExistentProtocol &&
          this.modalExistentProtocol.open()
        }
      },
      () => {
        this.props.dismissLoading();
      }
    );
  }

  renderExistentProtocolModal() {
    return (
      <Modal
        ref={(ref) => (this.modalExistentProtocol = ref)}
        title="Fique Atento!"
        shown={false}
        closeButton={true}
        declineButton={false}
        confirmButton={true}
        confirmButtonText="Fechar"
        confirmButtonAction={(modal) => { modal.close() }}
      >
        Você já possui esse serviço contratado.
        <br />
       
        <img src={WarningImage} height={140} alt="Modal img" />
      </Modal>
    );
  }

  render() {
    const { address, onSubmit } = this.props;
    const { whenWillLeave, leavingDate } = this.state;

    return (
      <>
        <PageHeaderImage src={WarningImage} height={80} />
        <h1>Cancelamento de Cadastro</h1>
        <p>
          Após o cancelamento,
          haverá interrupção no fornecimento de gás em até 6 dias úteis.
        </p>

        <ServiceItem className="address-item">
          <IconHome size={40} />
          <p>{address.endereco}</p>
          <span
            onClick={() => redirect(this.props, "/address-list")}
            style={{ cursor: "pointer" }}
          >
            <IconClose size={30} />
          </span>
        </ServiceItem>

        <p>
          <b>E quando sairá do imóvel?</b>
        </p>
        <p style={{ textAlign: "left", margin: "0 auto", width: "180px" }}>
          <label>
            <input
              type="radio"
              name="when-will-leave"
              checked={whenWillLeave === "ja sai"}
              onChange={(e) =>
                this.setState({
                  whenWillLeave: "ja sai",
                  leavingDate: moment().format("DD/MM/YYYY"),
                })
              }
            />
            Já saí do imóvel
          </label>
        </p>
        <p style={{ textAlign: "left", margin: "0 auto", width: "180px" }}>
          <label>
            <input
              type="radio"
              name="when-will-leave"
              checked={whenWillLeave === "no dia"}
              onChange={(e) =>
                this.setState({
                  whenWillLeave: "no dia",
                  leavingDate: null,
                })
              }
            />
            Sairei do imóvel no dia:
          </label>
        </p>

        <ThemeProvider
          theme={createMuiTheme(
            {
              palette: {
                primary: { main: "#185087" },
              },
            },
            ptBR
          )}
        >
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={moment.locale("pt-br")}
          >
            <DatePicker
              locale="pt_BR"
              style={{ margin: "1rem auto" }}
              disabled={whenWillLeave !== "no dia"}
              cancelLabel="Cancelar"
              onChange={(date) => {
                if (date.isSameOrAfter(moment(), "day"))
                  this.setState({ leavingDate: date.format("DD/MM/YYYY") });
                else
                  this.props.showToast(
                    "Ops",
                    "Você deve selecionar uma data futura."
                  );
              }}
              labelFunc={() => leavingDate || "00/00/0000"}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>

        <br />

        <button
          className="block"
          disabled={
            whenWillLeave === null || this.state.protocolExists || (whenWillLeave === "no dia" && !leavingDate)
          }
          onClick={() => this.handleAvancar(onSubmit, whenWillLeave, leavingDate)}
        >
          Avançar
        </button>

        {this.renderExistentProtocolModal()}
      </>
    );
  }
}

class CancellationConfirmation extends Component {
  static contextType = apiContext;

  handleOrder = () => {
    const { address, complementaryData, onSubmit } = this.props;
    const {
      CPFCNPJ,
      email,
      [`nome-emit`]: nome,
      telefone,
    } = ClienteService.localEntrega;
    const { [`local-entrega`]: localEntrega } = address;

    const splitedName = nome.split(" ");
    const firstName = splitedName.shift();
    const lastName = splitedName.join(" ");

    this.props.showLoading()

    this.context.ConsigazApi.geraOcorrencia(
      null,
      "Cancelamento",
      null,
      null,
      null,
      CPFCNPJ,
      localEntrega,
      {
        contact: complementaryData?.whoWillReceiveTheTechnician || firstName,
        lastNameDept: lastName,
        phone: complementaryData?.userPhone || telefone,
        email: email,
        complementaryData,
        leavingDate: complementaryData.leavingDate,
        portariaEletronica: complementaryData.electronicConcierge ? 'YES' : 'NO',
        moradorNoLocal: complementaryData.presenceNext3Days ? 'YES' : 'NO',
      }
    ).then(
      ({ Ocorrencia }) => {
        this.props.dismissLoading()

        if (Ocorrencia)
          onSubmit &&
            onSubmit({
              protocolNumber: Ocorrencia[0]["v-nr-ocorrencia"],
              success: true,
            });
        else
          this.props.showToast(
            "Ops!",
            "Não foi possível localizar o número do protocolo"
          );
      },
      () => this.props.showToast("Ops!", "Erro ao realizar o cancelamento")
    );
  };

  render() {
    const { handleGoBack, address, complementaryData } = this.props;

    return (
      <>
        <PageHeaderImage src={WarningImage} height={80} />
        <h1>Confirmar Cancelamento?</h1>
        <p>
          Você tem certeza que deseja cancelar o seu cadastro? Haverá
          interrupção do fornecimento de gás em até 6 dias úteis.
        </p>

        <ServiceItem className="address-item">
          <IconHome size={40} />
          <p className="text-blue">{address.endereco}</p>
          <span
            onClick={() => redirect(this.props, "/address-list")}
            style={{ cursor: "pointer" }}
          >
            <IconClose size={30} />
          </span>
        </ServiceItem>

        {complementaryData.leavingDate && (
          <ServiceItem>
            <h2 className="text-blue">Data em que deixará o imóvel:</h2>
            <span className="value text-blue">{complementaryData.leavingDate}</span>
          </ServiceItem>
        )}

        {complementaryData.whoWillReceiveTheTechnician && (
          <ServiceItem>
            <h2 className="text-blue">Quem irá receber o técnico:</h2>
            <span className="value text-blue">{complementaryData.whoWillReceiveTheTechnician}</span>
          </ServiceItem>
        )}

        {complementaryData.leavingDate && (
          <ServiceItem>
            <h2 className="text-blue">Prazo de cancelamento:</h2>
            <span className="value text-blue">6 dias úteis</span>
          </ServiceItem>
        )}

        <br />
        <br />

        <button className="block" onClick={this.handleOrder}>
          Confirmar Cancelamento do Cadastro
        </button>
        <br />
        <button className="block link" onClick={handleGoBack}>
          Editar Dados
        </button>
      </>
    );
  }
}

const CancellationDone = (props) => (
  <>
    <PageHeaderImage src={WarningImage} height={80} />
    <h1>Em processo de cancelamento</h1>
    <p>Anote o protocolo informado abaixo</p>

    <p
      style={{
        color: "var(--light-blue)",
        fontSize: "26px",
        fontWeight: 600,
        fontStyle: "oblique",
      }}
    >
      {props.complementaryData.protocolNumber}
    </p>

    <button className="block" onClick={() => redirect(props, "/home")}>
      Ok, entendi!
    </button>
  </>
);

class CancellationError extends Component {
  static contextType = apiContext;

  sendMail = () => {
    const { complementaryData } = this.props;
    const { CPFCNPJ, email, [`nome-emit`]: nome } = ClienteService.localEntrega;
    this.context.ConsigazReportApi.sendCancelationEmail(
      nome,
      CPFCNPJ,
      moment(complementaryData.leavingDate, "DD/MM/YYYY")
        .subtract(2, "days")
        .format("DD/MM/YYYY"),
      email
    ).then(
      () => {
        this.props.showToast(
          "Enviado",
          "Você receberá um email para lembrar de fazer o cancelamento."
        );
        setTimeout(() => {
          redirect(this.props, "/home");
        }, 2000);
      },
      () => this.props.showToast("Ops!", "Não foi possível enviar o email.")
    );
  };

  render() {
    return (
      <>
        <PageHeaderImage src={WarningImage} height={80} />
        <h1>Não foi possível cancelar</h1>
        <p>
          Após o cancelamento da conta, o fornecimento de gás será interrompido
          dentro das próximas 72 horas. Deixe para efetuar o cancelamento a
          partir de 6 dias antes da data em que irá deixar o imóvel ou após a sua
          saída.
        </p>
        <p>
          <b>Mas não se preocupe! Nós podemos te mandar um email 6 dias antes da</b> data
          selecionada para te lembrar de fazer o cancelamento.
        </p>

        <button className="block" onClick={this.sendMail}>
          Quero receber o email
        </button>
        <br />
        <button
          className="block link"
          onClick={() => redirect(this.props, "/home")}
        >
          Voltar para home
        </button>
      </>
    );
  }
}

class Cancellation extends Component {
  static contextType = apiContext;

  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      complementaryData: {},
    };
  }

  get address() {
    const { location } = this.props;

    if (location && location.state) return location.state.address;

    return null;
  }

  handleStepBack = () => {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep - 1 });
  };

  handleStepSubmit = (data) => {
    const { currentStep, complementaryData } = this.state;
    this.setState({
      currentStep: currentStep + 1,
      complementaryData: { ...complementaryData, ...data },
    });
  };

  render() {
    const { redirectState, currentStep, complementaryData } = this.state;
    const address = this.address;

    return (
      <HomeTemplate
        ref={(ref) => (this.template = ref)}
        history={this.props.history}
        redirectFrom="Cancellation"
        redirectState={redirectState}
        className="cancellation-page"
      >
        {(
          [
            () => (
              <CancellationComplement
                history={this.props.history}
                complementaryData={complementaryData}
                address={address}
                showToast={this.props.showToast}
                handleGoBack={this.handleStepBack}
                onSubmit={this.handleStepSubmit}
                showLoading={this.props.showLoading}
                dismissLoading={this.props.dismissLoading}
              />
            ),
            () =>  complementaryData.success ?  (
              <CancellationExtraInfos
                history={this.props.history}
                complementaryData={complementaryData}
                showToast={this.props.showToast}
                handleGoBack={this.handleStepBack}
                onSubmit={this.handleStepSubmit}
              />
            ) : (
              <CancellationError
                history={this.props.history}
                complementaryData={complementaryData}
                address={address}
                showToast={this.props.showToast}
                handleGoBack={this.handleStepBack}
                onSubmit={this.handleStepSubmit}
              />
            ),
            () => (
              <CancellationConfirmation
                history={this.props.history}
                complementaryData={complementaryData}
                address={address}
                handleGoBack={this.handleStepBack}
                onSubmit={this.handleStepSubmit}
                showToast={this.props.showToast}
                showLoading={this.props.showLoading}
                dismissLoading={this.props.dismissLoading}
              />
            ),
            () => (
                <CancellationDone
                  history={this.props.history}
                  complementaryData={complementaryData}
                  address={address}
                  handleGoBack={this.handleStepBack}
                  onSubmit={this.handleStepSubmit}
                />
              )
          ][currentStep] || (() => null)
        )()}
      </HomeTemplate>
    );
  }
}

class CancellationExtraInfos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      electronicConcierge: null,
      presenceNext3Days: null,
      whoWillReceiveTheTechnician: null,
      userPhone: null,
    };
  }

  maskPhone(v) {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");

    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }

    if(r.length === 10) r = r.replace("-", "")
    if(r.length === 1 && r === '(') r = r.replace("(", "")
    
    this.setState({ ...this.state, userPhone: r })
  }

  componentDidMount() {
    
    this.setState({
      ...this.props.complementaryData
    })
  }
  
  handleChangeNex3Days(value) {
    if(value) {
      const { [`nome-emit`]: nome, telefone } = ClienteService.localEntrega;
      this.setState({ ...this.state, whoWillReceiveTheTechnician: nome, userPhone: telefone, presenceNext3Days: value })
    } else {
      this.setState({ ...this.state, whoWillReceiveTheTechnician: "", userPhone: "", presenceNext3Days: value })
    }
  }

  render() {
    const { onSubmit } = this.props;
    const { 
      electronicConcierge, 
      presenceNext3Days, 
      whoWillReceiveTheTechnician, 
      userPhone 
    } = this.state;

    return (
      <>
        <PageHeaderImage src={WarningImage} height={80} />
        <h1>Cancelamento de Cadastro</h1>
        <p>
          Após o cancelamento,
          haverá interrupção no fornecimento de gás em até 6 dias úteis.
        </p>


        <div className="wrap-itens">

          <div className="item">
            <p style={{ padding: '5px 25px', margin: 0 }} className="text-blue">
              O condomínio tem portaria eletrônica?
            </p>
            <div style={{ display: 'flex', minWidth: '200px' }}>
              <p style={{ textAlign: "left", margin: "0 auto" }}>
                <label>
                  <input
                    type="radio"
                    name="eletronic-concierge"
                    checked={this.state.electronicConcierge}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        electronicConcierge: true,
                      })
                    }
                  />
                  Sim
                </label>
              </p>

              <p style={{ textAlign: "left", margin: "0 auto" }}>
                <label>
                  <input
                    type="radio"
                    name="eletronic-concierge"
                    checked={this.state.electronicConcierge === false}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        electronicConcierge: false,
                      })
                    }
                  />
                  Não
                </label>
              </p>
            </div>
          </div>

          {electronicConcierge && <>
            <div className="item">
              <p style={{ padding: '5px 25px', margin: 0 }} className="text-blue border-bottom">
                Estará no local nos próximos 6 dias úteis para liberar a entrada do técnico?
              </p>
              <div style={{ display: 'flex', minWidth: '200px' }}>
                <p style={{ textAlign: "left", margin: "0 auto" }}>
                  <label>
                    <input
                      type="radio"
                      name="presence-next-3-days"
                      checked={this.state.presenceNext3Days}
                      onChange={(e) => this.handleChangeNex3Days(true)}
                    />
                    Sim
                  </label>
                </p>

                <p style={{ textAlign: "left", margin: "0 auto" }}>
                  <label>
                    <input
                      type="radio"
                      name="presence-next-3-days"
                      checked={this.state.presenceNext3Days === false}
                      onChange={(e) => this.handleChangeNex3Days(false)}
                    />
                    Não
                  </label>
                </p>
              </div>
            </div>

            {presenceNext3Days === false && 
              <div className="item">
                <p style={{ padding: '5px 25px', margin: 0 }} className="text-blue border-bottom">
                  Quem irá receber o técnico?
                </p>
                <div style={{ display: 'flex', minWidth: '200px' }}>
                  <input
                    style={{ marginRight: '20px' }}
                    type="text"
                    placeholder="nome"
                    name="when-will-leave"
                    value={this.state.whoWillReceiveTheTechnician}
                    onChange={(e) => 
                      this.setState({
                        ...this.state,
                        whoWillReceiveTheTechnician: e.target.value,
                      })
                    }
                  />

                  <input
                    type="text"
                    name="when-will-leave"
                    value={this.state.userPhone}
                    placeholder="telefone"
                    onChange={(e) => 
                      this.maskPhone(e.target.value)
                    }
                  />


                </div>
              </div>}
          </>}
        </div>

        <br />

        <button
          className="block"
          disabled={
            electronicConcierge === null || (presenceNext3Days === null && electronicConcierge) || (presenceNext3Days === false && (whoWillReceiveTheTechnician?.length === 0 || userPhone?.length === 0))
          }
          onClick={() => onSubmit({ ...this.state })}
        >
          Avançar
        </button>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToast: toastOperations.show,
      showLoading: loadingOperations.show,
      dismissLoading: loadingOperations.dismiss,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Cancellation);
